import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_SMS_MESSAGE = gql`
    query getSMSMessage
    {
        getSMSMessage{
            sms_message
        }
    }
`;


function GetSMS()
{
       
    //executing the query
    const { loading, error, data } = useQuery(GET_SMS_MESSAGE)

     if (loading) return "LOADING";
     if(error){
         console.log(error)
         return [];
     }
  return data.getSMSMessage;
  
}

export default GetSMS;
