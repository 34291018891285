
import gql from "graphql-tag"
const GRAPHQL_PATH = "http://localhost:4000/graphql";

const GET_SERVICE_DETAILS = gql`
    query getService($id: Int!)
    {
        getService(id: $id){
            id
            name
            duration
        }
        getPhysiosWhoPerformService(id:$id){
            id
            name
        }
    }
`;

const DEL_SERVICE = gql`
    mutation delService($id: Int!) {
        deleteService(id: $id)
    }
`;

const EDIT_SERVICE = gql`
    mutation editService($id: Int!, $name: String, $duration: Int, $PhysioIds: [Int]) {
        editService(id: $id, name: $name, duration: $duration, PhysioIds: $PhysioIds){
            id
        }
    }
`;
const ADD_SERVICE = gql`
    mutation addService($name: String!, $duration: Int!,$PhysioIds: [Int]) {
        addService(name: $name, duration: $duration, PhysioIds: $PhysioIds){
            id
        }
    }
`;



const GET_PHYSIO_DETAILS = gql`
    query getPhysio($id: Int!)
    {
        getPhysio(id: $id){
            id
            name
            email
            calendar_reference
        }
        getServicesDoneByPhysio(id:$id){
            id
            name
        }
    }
`;

const DEL_PHYSIO = gql`
    mutation delPhysio($id: Int!) {
        deletePhysio(id: $id)
    }
`;

const EDIT_PHYSIO = gql`
    mutation editPhysio($id: Int!, $name: String, $calendar_reference: String, $email: String, $ServiceIds: [Int]) {
        editPhysio(id: $id, name: $name, calendar_reference: $calendar_reference, email: $email,ServiceIds: $ServiceIds){
            id
        }
    }
`;
const ADD_PHYSIO = gql`
    mutation addPhysio($name: String!, $calendar_reference: String!, $email: String!, $ServiceIds: [Int]) {
        addPhysio(name: $name, calendar_reference: $calendar_reference, email: $email, ServiceIds: $ServiceIds){
            id
        }
    }
`;
const ADJUST_HOURS = gql`
    mutation adjustHours($openingHours: Time!, $closingHours: Time!) {
        adjustHours(openingHours: $openingHours, closingHours: $closingHours)
    }
`;
const ADJUST_CONCURRENT_APPOINTMENTS = gql`
    mutation adjustConcurrentAppointments($concurrentAppointments: Int!) {
        adjustConcurrentAppointments(concurrentAppointments: $concurrentAppointments)
    }
`;
const ADJUST_SMS_MESSAGE = gql`
    mutation adjustSMSMessage($SMS: String!) {
        adjustSMSMessage(SMS: $SMS)
    }
`;

const GET_CONCURRENT_APPOINTMENTS = gql`
    query getConcurrentAppointments
    {
        getConcurrentAppointments{
            concurrent_apts
        }
    }
`;




const ADD_NEWS = gql`
  mutation addNews($headline: String!, $small_img_pic_path: Upload!, $large_img_pic_path: Upload!, $text: String!, $short_description: String!, $homepage: Int!, $date: Date! ) {
    addNews(headline: $headline, small_img_pic_path: $small_img_pic_path, large_img_pic_path: $large_img_pic_path, text: $text, short_description: $short_description,  homepage: $homepage, date: $date)    {
      id
    }
  }
`;
const DEL_NEWS = gql`
  mutation deleteNews($id: Int!) {
    deleteNews(id: $id)
  }
`;
const EDIT_NEWS = gql`
  mutation editNews($id: Int!, $headline: String, $small_img_pic_path: Upload, $large_img_pic_path: Upload, $text: String, $short_description: String, $homepage: Int, $date: Date) {
    editNews(id: $id, headline: $headline, small_img_pic_path: $small_img_pic_path, large_img_pic_path: $large_img_pic_path, short_description: $short_description,  text: $text, homepage: $homepage, date: $date){
      id
    }
  }
`;
const ADD_EVENT = gql`
  mutation addEvent($applied_content: String!, $password_string: String, $password_required: Int!, $num_of_events: Int, $name: String!, $date: Date!, $time: Time!, $simulator: String!, $drivers: Int!, $rem_places: Int!, $small_img_pic_path: Upload!, $large_img_pic_path: Upload!, $rules: String!, $apply_prompt: String!, $event_type: String!, $platform: String!, $invite_only: Int!, $championship_id: Int) {
    addEvent(applied_content: $applied_content, password_string: $password_string, password_required: $password_required, name: $name, num_of_events: $num_of_events, date: $date, time: $time, simulator: $simulator, drivers: $drivers, rem_places: $rem_places, small_img_pic_path: $small_img_pic_path, large_img_pic_path: $large_img_pic_path, rules: $rules, apply_prompt: $apply_prompt, event_type: $event_type, platform: $platform, invite_only: $invite_only, championship_id: $championship_id )    {
      id
    }
  }
`;
const DEL_EVENT = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id)
  }
`;

const SEND_EMAIL = gql`
  mutation sendEmailToDrivers($drivers: [String]!, $subject: String!, $text: String!) {
    sendEmailToDrivers(drivers: $drivers, subject: $subject, text: $text)
  }
`;

const GET_NEWS_ARTICLE = gql`

query getArticle($id: Int!)
    {
        getArticle(id: $id){
            id
            text
            headline
            large_img_pic_path
            small_img_pic_path
            homepage
            date
            short_description
        }
    }

`;
const GET_EVENT_DETAILS = gql`

query getEventDetails($id: Int!)
    {
        getEventDetails(id: $id){
            event_detail{
                id
                platform
                name
                date
                time
                rules
                drivers
                simulator
                event_type
                rem_places
                invite_only
                large_img_pic_path
                small_img_pic_path
                apply_prompt
                password_string
                password_required
                applied_content
            }
            drivers_in_event{
                email
                full_name
                team_name
            }
            button_to_show
        }
    }
`;
const GET_ALL_USERS = gql`

query getAllUsers
    {
      getAllDrivers{
          email
          full_name
          team_name
        }
    }
`;

const ADD_DRIVER_TO_EVENT = gql`
  mutation applyAllToEvent($drivers: [String]!, $event_id: Int!) {
    applyAllToEvent(drivers: $drivers, event_id: $event_id)
  }
`;
export{
    ADD_NEWS,
    DEL_NEWS,
    EDIT_NEWS,
    GET_NEWS_ARTICLE,
    GET_EVENT_DETAILS,
    ADD_EVENT,
    DEL_EVENT,
    GET_ALL_USERS,
    SEND_EMAIL,
    ADD_DRIVER_TO_EVENT,
    GRAPHQL_PATH,
    GET_SERVICE_DETAILS,
    DEL_SERVICE,
    EDIT_SERVICE,
    ADD_SERVICE,
    GET_PHYSIO_DETAILS,
    DEL_PHYSIO,
    EDIT_PHYSIO,
    ADD_PHYSIO,
    ADJUST_HOURS,
    ADJUST_CONCURRENT_APPOINTMENTS,
    ADJUST_SMS_MESSAGE,
    GET_CONCURRENT_APPOINTMENTS
}