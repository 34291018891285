import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
 
const GET_PHYSIOTHERAPISTS = gql`

    query getPhysiotherapists
    {
        getAllPhysios{
            id
            name
        }
    }

`; 

function GetPhysiotherapists()
{
    //executing the query
    const { loading, error, data } = useQuery(GET_PHYSIOTHERAPISTS)

     if (loading) return 'LOADING';
     if (error){
         console.log(error)

     }
  return data.getAllPhysios;
  
}

export default GetPhysiotherapists;
