import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import React, { useEffect, useState } from "react";
import { useAuth0 } from "./react-auth0-spa";
import Admin from './Admin';
import { setContext } from 'apollo-link-context';
import {ApolloClient} from "apollo-client"
import {ApolloProvider, Mutation} from "react-apollo"
import {graphQL} from "./auth_config.json"

const apolloCache = new InMemoryCache()
const uploadLink = createUploadLink({
  uri: graphQL,
  headers: {
    "keep-alive": "true"
  }
})

const Profile = () => {
  const { isAuthenticated, getTokenSilently, loading, user } = useAuth0();
  const [client, setClient] = useState();
  const getClient = async () => {
      try {
          const clientApollo = await getTokenSilently().then(response => {


            const authLink = setContext((_, { headers }) => {
              // return the headers to the context so httpLink can read them
              return {
                headers: {
                  ...headers,
                  authorization: `Bearer ${response}` ,
                }
              }
            });
            var clientApollo = new ApolloClient({
              cache: apolloCache,
              link: authLink.concat(uploadLink),
              onError: ({ graphQLErrors, networkError, operation, forward }) => {
                if (networkError) {
                  
                }
              },
            });
            return clientApollo
          });
          return clientApollo

      } catch (error) {
          console.error(error);
      }
 };
  useEffect(
    () => {
        if(isAuthenticated){
        getClient()
        .then(res => {
            
            if(res !== undefined)  
            setClient(res);
        })
        .catch(error => {
            console.warn(JSON.stringify(error, null, 2));
        });
        }else{
          
        setClient()
        }
    }, [isAuthenticated]
    )
  
    if (loading || !user) {
      return <div>Loading...</div>;
    }

    if(client !== undefined){
      return (
        
        <ApolloProvider client = {client}>
          <Admin />
        </ApolloProvider>
      );
    }
    else{
      
      return <></>
    }
};

export default Profile;