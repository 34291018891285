import './Admin.css';
import './App.css';
import logo from './logo_cropped.jpg';
import React, {useState, useEffect} from 'react';
import GetPhysiotherapists from './getPhysiotherapists'
import GetServices from './getServices';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAuth0 } from "./react-auth0-spa";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {Mutation} from "react-apollo"
import { useLazyQuery } from '@apollo/react-hooks';
import {
  EDIT_SERVICE,
  GET_SERVICE_DETAILS,
  DEL_SERVICE,
  ADD_EVENT,
  DEL_EVENT,
  ADD_SERVICE,
  GET_PHYSIO_DETAILS,
  ADD_PHYSIO,
  EDIT_PHYSIO,
  DEL_PHYSIO,
  ADJUST_HOURS,
  ADJUST_CONCURRENT_APPOINTMENTS,
  ADJUST_SMS_MESSAGE,
  GET_CONCURRENT_APPOINTMENTS,
  GET_SMS_MESSAGE
} from './typedefs'
import 'date-fns';
import 'react-tabs/style/react-tabs.css';
import 'react-select-search/style.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import GetSMS from "./getSMS";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    border                : '2px solid tgba(0,0,0,0.125)',
    boxShadow             : '2px 2px 10px 2px #9c9c9c',
    maxHeight             : '80vh',
    maxWidth              : '95vw',
    width                 : '600px',
    zIndex                : '1000'
  }
};

let userToken;

function PhysioModalContent(props) {
  const result = GetServices();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [chosenServices, setChosenServices] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const onServicesChange = (event, values) => {
    setChosenServices(values);
  }

  const services = []
  if(result !== undefined && result !== 'LOADING' && services.length === 0){
    for (let i = 0; i < result.length; i++) {
      services.push({id: result[i].id, name: result[i].name})
    }
  }
  useEffect(() => {
    setName(props.row.getPhysio === undefined ? "" : props.row.getPhysio.name );
    setEmail(props.row.getPhysio === undefined ? "" : props.row.getPhysio.email);
    setCalendarName(props.row.getPhysio === undefined ? "" : props.row.getPhysio.calendar_reference);
  }, [props]);
  useEffect(() => {
    if(props.row.getServicesDoneByPhysio !== undefined && props.row.getServicesDoneByPhysio.length>0 ){
      let temp = []
      for (let i = 0; i < props.row.getServicesDoneByPhysio.length; i++)
      {
        for (let j = 0; j < services.length; j++)
        {
          if ((services[j])['id'] === props.row.getServicesDoneByPhysio[i].id)
          {
            temp.push(services[j])
          }
        }
      }
      setChosenServices(temp)
    }
  }, [result, props]);

  if (props.configuration === "delete")
  {
    return(
        <div className="confirmation_wrapper">
          <div className="confirmation">
            <h3 style={{paddingBottom:"20px", textAlign:"center", color:"#333333"}}>Are you sure you want to remove doctor "{props.row.name}"?</h3>

            <Mutation mutation={DEL_PHYSIO}>
              {(delPhysio) => {
                return(
                    <Button style={{backgroundColor:"#BE1E2D"}} disabled={disabled} className="contact_button" variant="contained" color="primary" type="submit" onClick=
                        {e=> {
                          setDisabled(true);
                          delPhysio({
                            variables: {
                              id: props.row.id,
                            }
                          }).then((result) => {

                            window.location.reload()
                          })
                        }

                        }>
                      <p style={{margin:"0px"}}>Delete</p>
                    </Button>
                )
              }}
            </Mutation>
          </div>
        </div>
    );
  }
  else if (props.configuration === "add")
  {
    return(
        <div>
          <Mutation mutation={ADD_PHYSIO}>
            {(addPhysio) => {
              return (
                  <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
                    setDisabled(true);
                    e.preventDefault()

                    addPhysio({
                      variables: {
                        name:name,
                        email:email,
                        calendar_reference: calendarName,
                        ServiceIds: chosenServices.map(x => x.id)
                      }
                    }).then((result) => {

                      window.location.reload()
                    }).catch((err) => console.error(err))

                  }}>
                    <div className="contact_div">
                      <TextField id="outlined-basic" label="Name" variant="outlined" onChange={(e) => {setName(e.target.value)}} style={{width:"100%"}} required/>
                    </div>
                    <div className="contact_div">
                      <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => {setEmail(e.target.value)}} style={{width:"100%"}} required/>
                    </div>
                    <div className="contact_div">
                      <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={services}
                          getOptionLabel={option => option.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={chosenServices}
                          onChange={onServicesChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Services"
                              />
                          )}
                      />
                    </div>
                    <div className="contact_div">
                      <FormControl style={{width:"100%"}}>
                        <TextField id="outlined-basic" label="Calendar ID" aria-describedby="my-helper-text" variant="outlined" onChange={(e) => {setCalendarName(e.target.value)}} required/>
                        <FormHelperText id="my-helper-text">Note: Clinician should enable Google Calendar notifications for "New events"</FormHelperText>
                      </FormControl>
                    </div>
                    <div className="button_div">
                      <Button className="contact_button" disabled={disabled} variant="contained" color="primary" type="submit">
                        Add
                      </Button>
                    </div>
                  </form>
              )
            }}
          </Mutation>
        </div>
    );
  }
  else if (props.configuration === "edit")
  {
    return(
        <div>
          <Mutation mutation={EDIT_PHYSIO}>
            {(editPhysio) => {
              return (
                  <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
                    setDisabled(true);
                    e.preventDefault()

                    editPhysio({
                      variables: {
                        id: props.row.getPhysio.id,
                        name:name,
                        email:email,
                        calendar_reference: calendarName,
                        ServiceIds: chosenServices.map(x => x.id)
                      }
                    }).then((result) => {
                      window.location.reload()
                    })

                  }}>
                    <div className="contact_div">
                      <TextField id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(e) => {setName(e.target.value)}} style={{width:"100%"}} required/>
                    </div>
                    <div className="contact_div">
                      <TextField id="outlined-basic" label="Email" variant="outlined" value={email} onChange={(e) => {setEmail(e.target.value)}} style={{width:"100%"}} required/>
                    </div>
                    <div className="contact_div">
                      <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={services}
                          getOptionLabel={option => option.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={chosenServices}
                          onChange={onServicesChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Services"
                              />
                          )}
                      />
                    </div>
                    <div className="contact_div">
                      <TextField id="outlined-basic" label="Calendar ID" variant="outlined" value={calendarName} onChange={(e) => {setCalendarName(e.target.value)}} style={{width:"100%"}} required/>
                    </div>
                    <div className="button_div">
                      <Button className="contact_button" disabled={disabled} variant="contained" color="primary" type="submit">
                        Change
                      </Button>
                    </div>
                  </form>
              )
            }}
          </Mutation>
        </div>
    );
  }
}

function Physios(){

  const result = GetPhysiotherapists();
  const [configuration, setConfiguration] = useState(null);
  const [dataToSend, setData] = useState(null);
  const [modalIsOpen,setIsOpen] = useState(false);
  const [getPhysio, { loading, data, error }] = useLazyQuery(GET_PHYSIO_DETAILS,  {fetchPolicy: 'network-only',onCompleted: (data) => {
      setData(data);
      openModal();
  }})
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState();
  if(token === undefined){
    getTokenSilently().then(response => {setToken(response)})
  }

  function openModal() {
    console.log(data)
    setIsOpen(true);
  }
  function closeModal(){
    setIsOpen(false);
  }

  if(result === 'LOADING'){
    return(
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh"
        }}>
          <Loader
              type="Oval"
              color="#333333"
              timeout={30000} //30 secs
          />
        </div>
    );
  }
  else
  {
    return(
        <>
          <div className="top_content">
            <h1>Clinicians</h1>
            <button className="button_no_style" onClick={()=>
            {
              setConfiguration("add");
              setData({homepage: 0});
              openModal()
              ;
            }}>
              <i className="fas fa-plus-circle fa-3x"></i>
            </button>
          </div>

          <div className = 'category'>
            {result.map(row => (
                <div className="collapsible">
                  <h3>{row.name}</h3>
                  <div className="buttons_manager">
                    <button className="button_no_style" onClick={()=>
                    {
                      setConfiguration("edit");
                      getPhysio({ variables: { id: row.id } })

                    }}>
                      <i className="fas fa-pen-square fa-2x"></i>
                    </button>
                    <button className="button_no_style" onClick={()=>
                    {
                      setConfiguration("delete");
                      setData(row);
                      openModal();
                    }}>
                      <i className="fas fa-trash-alt fa-2x"></i>
                    </button>
                  </div>
                </div>
            ))}
          </div>

          <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
          >
            <PhysioModalContent configuration={configuration} row={dataToSend} />
          </Modal>

        </>
    );
  }
}

function ServiceModalContent(props){

  const result = GetPhysiotherapists();
  const [name, setName] = useState(props.row.getService !== undefined ? props.row.getService.name : "");
  const [duration, setDuration] = useState( props.row.getService !== undefined ? props.row.getService.duration : 15);
  const [chosenDoctors, setChosenDoctors] = React.useState([]);
  const [disabled, setDisabled] = useState(false);

  const onDoctorsChange = (event, values) => {
    setChosenDoctors(values);
  }

  const doctors = []
  if(result !== undefined && result !== 'LOADING' && doctors.length === 0){
    for (let i = 0; i < result.length; i++) {
      doctors.push({id: result[i].id, name: result[i].name})
    }
  }

  useEffect(() => {
    if(result !== 'LOADING' && props.row.getPhysiosWhoPerformService !== undefined && props.row.getPhysiosWhoPerformService.length>0 ){
      let temp = []
      for (let i = 0; i < props.row.getPhysiosWhoPerformService.length; i++) {
        for (let j = 0; j < doctors.length; j++)
        {
          if ((doctors[j])['id'] === props.row.getPhysiosWhoPerformService[i].id)
          {
            temp.push(doctors[j])
          }
        }
      }
      setChosenDoctors(temp)
    }
  }, [result]);

  if (props.configuration === "delete")
  {
    return(
      <div className="confirmation_wrapper">
        <div className="confirmation">
          <h3 style={{paddingBottom:"20px", textAlign:"center", color:"#333333"}}>Are you sure you want to remove service "{props.row.name}"?</h3>
          
          <Mutation mutation={DEL_SERVICE}>
            {(delService) => {
              return(
                <Button style={{backgroundColor:"#BE1E2D"}} disabled={disabled} className="contact_button" variant="contained" color="primary" type="submit" onClick=
                {()=> {
                  setDisabled(true);
                  delService({
                      variables: {
                        id: props.row.id,
                      }
                    }).then((result) => {
                      
                      window.location.reload()
                    })
                  }
                    
                    }>
                  <p style={{margin:"0px"}}>Delete</p>
                </Button>
              )               
            }}
          </Mutation>
            
        </div>
      </div>
    );
  }
  else if (props.configuration === "add")
  {
    return(
      <div>
        <Mutation mutation={ADD_SERVICE}>
          {(addService) => {
            return (

              <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
                setDisabled(true);
                e.preventDefault()

                addService({
                  variables: {    
                    name: name,
                    duration: parseInt(duration),
                    PhysioIds: chosenDoctors.map(x => x.id)
                  }
              }).then((result) => {
                    
                    window.location.reload()
                  }).catch((err) => console.error(err))

              }}>
                <div className="contact_div">
                  <TextField id="outlined-basic" label="Service" variant="outlined" value={name} onChange={(e) => {setName(e.target.value)}} style={{width:"100%"}} required/>
                </div>
                <div className="contact_div">
                  <TextField
                    id="outlined-basic"
                    label="Duration (min)"
                    type="number"
                    variant="outlined" 
                    value={duration}
                    onChange={(e) => {setDuration(e.target.value)}}
                    style={{width:"100%"}} required
                  />
                </div>
                <div className="contact_div">
                  <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={doctors}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      value={chosenDoctors}
                      onChange={onDoctorsChange}
                      filterSelectedOptions
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Clinicians"
                          />
                      )}
                  />
                </div>
                <div className="button_div">
                  <Button className="contact_button" disabled={disabled} variant="contained" color="primary" type="submit">
                    Add
                  </Button>
                </div>
              </form>
              )
            }}
          </Mutation>
      </div>
    );
  }
  else if (props.configuration === "edit")
  {
    return(
      <div>
        <Mutation mutation={EDIT_SERVICE}>
          {(editService) => {
            return (
              <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
                setDisabled(true);
                e.preventDefault()
                editService({
                  variables: {
                    id: props.row.getService.id,
                    name: name,
                    duration: parseInt(duration),
                    PhysioIds: chosenDoctors.map(x => x.id)
                  }
              }).then((result) => {
                    
                    window.location.reload()
                  })
              }}>
              <div className="contact_div">
                <TextField id="outlined-basic" label="Service" variant="outlined" value={name} onChange={(e) => {setName(e.target.value)}} style={{width:"100%"}} required/>
              </div>
              <div className="contact_div">
                <TextField
                  id="outlined-basic"
                  label="Durations (min)"
                  type="number"
                  variant="outlined"
                  value={duration}
                  onChange={(e) => {setDuration(e.target.value)}}
                  style={{width:"100%"}} required
                />
              </div>
              <div className="contact_div">
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={doctors}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={chosenDoctors}
                    onChange={onDoctorsChange}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Clinicians"
                        />
                    )}
                />
              </div>
              <div className="button_div">
                <Button className="contact_button" disabled={disabled} variant="contained" color="primary" type="submit">
                  Change
                </Button>
              </div>
            </form>
            )
            }}
        </Mutation>
      </div>
    );
  }
}

function Services(){

  const result = GetServices();
  const [configuration, setConfiguration] = useState(null);
  const [rowData, setData] = useState(null);
  const {getTokenSilently} = useAuth0();
  const [token, setToken] = useState();
  if(token === undefined){
    getTokenSilently().then(response => {setToken(response)})
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal(){
    setIsOpen(false);
  }
  const [getServiceDetails] = useLazyQuery(GET_SERVICE_DETAILS,{fetchPolicy: 'network-only',onCompleted: (data) => {
      setData(data);
      openModal();
    }})

  if(result === 'LOADING'){
    return(
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh"
        }}>
          <Loader
              type="Oval"
              color="#333333"
              timeout={30000} //30 secs
          />
        </div>
    );
  }
  else
  {
    return(
      <>
      <div className="top_content">
        <h1>Services</h1>
        <button className="button_no_style" onClick={()=>
        {
          setConfiguration("add");
          setData({checked: 0, event_type: 'CASUAL', platform: 'PC', simulator: 'rFactor2'});
          openModal();
        }}>
          <i className="fas fa-plus-circle fa-3x"/>
        </button>
      </div>

      <div className = 'category'>
        {result.map(row => (
          <div className="collapsible" >
              <h3>{row.name}</h3>
              <div className="buttons_manager">
                <button className="button_no_style" onClick={()=>
                {
                  setConfiguration("edit");
                  getServiceDetails({ variables: { id: row.id } })
                }}>
                  <i className="fas fa-pen-square fa-2x"/>
                </button>

                <button className="button_no_style" onClick={()=>
                {
                  setConfiguration("delete");
                  setData(row);
                  openModal();
                  }}>
                    <i className="fas fa-trash-alt fa-2x"/>
                  </button>
                </div>
            </div> 
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ServiceModalContent configuration={configuration} row={rowData}/>
      </Modal>

      </>
    );
  }
}

// function OpeningHours(props){
//
//   const data = GetHours();
//   const [openingHours, handleOpeningHourChange] = useState(new Date());
//   const [closingHours, handleClosingHourChange] = useState(new Date());
//   useEffect(() => {
//     if(data !== undefined && data !== null && data !== "LOADING"){
//       handleOpeningHourChange(new Date("2020-01-09T" + data.opening_hours ))
//       handleClosingHourChange(new Date("2020-01-09T" + data.closing_hours ))
//     }
//
//   }, [data]);
//
//   if (data === undefined)
//   {
//     return(
//       <Page loader={"rotate-spin"} color={"#333333"} size={10} duration={0.5} />
//     );
//   }
//   else
//   {
//     return(
//       <>
//         <div className="top_content">
//           <h1>Opening Hours</h1>
//         </div>
//         <div className="editor">
//
//           <Mutation mutation={ADJUST_HOURS}>
//             {(editHours) => {
//               return (
//                   <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
//                     e.preventDefault()
//                     editHours({
//                       variables: {
//                         openingHours: openingHours.toLocaleTimeString("en-GB",{hour12:false}),
//                         closingHours: closingHours.toLocaleTimeString("en-GB",{hour12:false}),
//                       }
//                     }).then((result) => {
//
//                       window.location.reload()
//                     })
//                   }}>
//
//                     <div className="clocks">
//                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                         <TimePicker
//                             autoOk
//                             ampm={false}
//                             variant="static"
//                             orientation="landscape"
//                             openTo="minutes"
//                             value={openingHours}
//                             onChange={handleOpeningHourChange}
//                         />
//                       </MuiPickersUtilsProvider>
//                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                         <TimePicker
//                             autoOk
//                             ampm={false}
//                             variant="static"
//                             orientation="landscape"
//                             openTo="minutes"
//                             value={closingHours}
//                             onChange={handleClosingHourChange}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </div>
//                     <div className="button_div">
//                       <Button className="contact_button" variant="contained" color="primary" type="submit">
//                         Change
//                       </Button>
//                     </div>
//                   </form>
//               )
//             }}
//           </Mutation>
//         </div>
//         <ClosingHours />
//       </>
//     );
//   }
// }
//
// function ClosingHoursModalContent(props){
//   const [reason, setReason] = useState(props.row.getEventDetails !== undefined ? props.row.getEventDetails.event_detail.name : "");
//   const [startDate, setStartDate] = React.useState(new Date());
//   const [endDate, setEndDate] = React.useState(new Date());
//
//   const handleStartDateChange = (date) => {
//     setStartDate(date);
//   };
//
//   const handleEndDateChange = (date) => {
//     setEndDate(date);
//   };
//
//   if (props.configuration === "delete")
//   {
//     return(
//         <div className="confirmation_wrapper">
//           <div className="confirmation">
//             <h3 style={{paddingBottom:"20px", textAlign:"center", color:"#333333"}}>Are you sure you want to remove "{props.row.name}"?</h3>
//
//             <Mutation mutation={DEL_EVENT}>
//               {(delEvents) => {
//                 return(
//                     <Button style={{backgroundColor:"#be1e2d"}} className="contact_button" variant="contained" color="primary" type="submit" onClick=
//                         {e=> {
//                           delEvents({
//                             variables: {
//                               id: props.row.id,
//                             }
//                           }).then((result) => {
//
//                             window.location.reload()
//                           })
//                         }
//
//                         }>
//                       <p style={{margin:"0px"}}>Delete</p>
//                     </Button>
//                 )
//               }}
//             </Mutation>
//
//           </div>
//         </div>
//     );
//   }
//   else if (props.configuration === "add")
//   {
//     return(
//         <div>
//           <Mutation mutation={ADD_EVENT}>
//             {(addEvent) => {
//               return (
//
//                   <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
//                     e.preventDefault()
//
//                     addEvent({
//                       variables: {
//                         name: reason
//                       }
//                     }).then((result) => {
//
//                       window.location.reload()
//                     }).catch((err) => console.error(err))
//
//                   }}>
//                     <div className="contact_div">
//                       <TextField id="outlined-basic" label="Reason" variant="outlined" value={reason} onChange={(e) => {setReason(e.target.value)}} style={{width:"100%"}} required/>
//                     </div>
//                     <div className="contact_div">
//                       <div className="date">
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                               disableToolbar
//                               variant="inline"
//                               format="MM/dd/yyyy"
//                               margin="normal"
//                               id="date-picker-inline"
//                               label="Date picker inline"
//                               value={startDate}
//                               onChange={handleStartDateChange()}
//                               style={{marginRight:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change date',
//                               }}
//                           />
//                           <KeyboardTimePicker
//                               margin="normal"
//                               id="time-picker"
//                               label="Time picker"
//                               value={endDate}
//                               onChange={handleEndDateChange()}
//                               style={{marginLeft:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change time',
//                               }}
//                           />
//                         </MuiPickersUtilsProvider>
//                       </div>
//                     </div>
//                     <div className="date">
//                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                         <KeyboardDatePicker
//                             disableToolbar
//                             variant="inline"
//                             format="MM/dd/yyyy"
//                             margin="normal"
//                             id="date-picker-inline"
//                             label="Date picker inline"
//                             value={endDate}
//                             onChange={handleEndDateChange()}
//                             KeyboardButtonProps={{
//                               'aria-label': 'change date',
//                             }}
//                             style={{marginRight:"10px"}}
//                         />
//                         <KeyboardTimePicker
//                             margin="normal"
//                             id="time-picker"
//                             label="Time picker"
//                             value={endDate}
//                             onChange={handleEndDateChange()}
//                             style={{marginLeft:"10px"}}
//                             KeyboardButtonProps={{
//                               'aria-label': 'change time',
//                             }}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </div>
//                     <div className="button_div">
//                       <Button className="contact_button" variant="contained" color="primary" type="submit">
//                         Add
//                       </Button>
//                     </div>
//                   </form>
//               )
//             }}
//           </Mutation>
//         </div>
//     );
//   }
//   else if (props.configuration === "edit")
//   {
//     return(
//         <div>
//           <Mutation mutation={EDIT_SERVICE}>
//             {(editService) => {
//               return (
//                   <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
//                     e.preventDefault()
//
//                     editService({
//                       variables: {
//                         id: props.row.getEventDetails.event_detail.id,
//                         name: reason,
//                       }
//                     }).then((result) => {
//
//                       window.location.reload()
//                     })
//                   }}>
//                     <div className="contact_div">
//                       <TextField id="outlined-basic" label="Reason" variant="outlined" value={reason} onChange={(e) => {setReason(e.target.value)}} style={{width:"100%"}} required/>
//                     </div>
//                     <div className="contact_div">
//                       <div className="date">
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                               disableToolbar
//                               variant="inline"
//                               format="MM/dd/yyyy"
//                               margin="normal"
//                               id="date-picker-inline"
//                               label="Date picker inline"
//                               value={startDate}
//                               onChange={handleStartDateChange()}
//                               style={{marginRight:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change date',
//                               }}
//                           />
//                           <KeyboardTimePicker
//                               margin="normal"
//                               id="time-picker"
//                               label="Time picker"
//                               value={endDate}
//                               onChange={handleEndDateChange()}
//                               style={{marginLeft:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change time',
//                               }}
//                           />
//                         </MuiPickersUtilsProvider>
//                       </div>
//                     </div>
//                     <div className="contact_div">
//                       <div className="date">
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                               disableToolbar
//                               variant="inline"
//                               format="MM/dd/yyyy"
//                               margin="normal"
//                               id="date-picker-inline"
//                               label="Date picker inline"
//                               value={endDate}
//                               onChange={handleEndDateChange()}
//                               style={{marginRight:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change date',
//                               }}
//                           />
//                           <KeyboardTimePicker
//                               margin="normal"
//                               id="time-picker"
//                               label="Time picker"
//                               value={endDate}
//                               onChange={handleEndDateChange()}
//                               style={{marginLeft:"10px"}}
//                               KeyboardButtonProps={{
//                                 'aria-label': 'change time',
//                               }}
//                           />
//                         </MuiPickersUtilsProvider>
//                       </div>
//                     </div>
//                     <div className="button_div">
//                       <Button className="contact_button" variant="contained" color="primary" type="submit">
//                         Change
//                       </Button>
//                     </div>
//                   </form>
//               )
//             }}
//           </Mutation>
//         </div>
//     );
//   }
// }
//
// function ClosingHours(){
//
//   const result = GetEvents();
//   const [configuration, setConfiguration] = useState(null);
//   const [rowData, setData] = useState(null);
//   const {getTokenSilently} = useAuth0();
//   const [token, setToken] = useState();
//   if(token === undefined){
//     getTokenSilently().then(response => {setToken(response)})
//   }
//   //modal
//   const [modalIsOpen,setIsOpen] = useState(false);
//   console.log(modalIsOpen)
//   function openModal() {
//     setIsOpen(true);
//   }
//   function closeModal(){
//     setIsOpen(false);
//     window.location.reload(false);
//   }
//
//   useEffect(() => {
//     if(result !== undefined && result !== null && result !== 'LOADING' && result.length > 0 ){
//       setData(result)
//       openModal()
//     }
//
//   }, [result]);
//
//   if(result === 'LOADING'){
//     return(
//         <Page loader={"rotate-spin"} color={"#333333"} size={10} duration={0.5} />
//     );
//   }
//   else
//   {
//     return(
//         <>
//           <div className="top_content">
//             <h1>Closing Days</h1>
//             <button className="button_no_style" onClick={()=>
//             {
//               setConfiguration("add");
//               setData({checked: 0, event_type: 'CASUAL', platform: 'PC', simulator: 'rFactor2'});
//               openModal();
//             }}>
//               <i className="fas fa-plus-circle fa-3x"/>
//             </button>
//           </div>
//
//           <div className = 'category'>
//             {result.map(row => (
//                 <div className="collapsible" >
//                   <h3>{row.name}</h3>
//                   <div className="buttons_manager">
//                     <button className="button_no_style" onClick={()=>
//                     {
//                       setConfiguration("edit");
//                       // getEventDetails({ variables: { id: row.id } })
//                     }}>
//                       <i className="fas fa-pen-square fa-2x"/>
//                     </button>
//
//                     <button className="button_no_style" onClick={()=>
//                     {
//                       setConfiguration("delete");
//                       setData(row);
//                       openModal();
//                     }}>
//                       <i className="fas fa-trash-alt fa-2x"/>
//                     </button>
//                   </div>
//                 </div>
//             ))}
//           </div>
//
//           <Modal
//               isOpen={modalIsOpen}
//               onRequestClose={closeModal}
//               style={customStyles}
//           >
//             <ClosingHoursModalContent configuration={configuration} row={rowData}/>
//           </Modal>
//
//         </>
//     );
//   }
// }

function SMS(props){
  const data = GetSMS();
  const [smsMessage, setSMSMessage] = useState("");
  useEffect(() => {
    if(data !== undefined && data !== null && data !== 'LOADING'){
      setSMSMessage(data.sms_message)
    }

  }, [data]);

  if (data === undefined || data === "LOADING" || data === null)
  {
    return(
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh"
        }}>
          <Loader
              type="Oval"
              color="#333333"
              timeout={30000} //30 secs
          />
        </div>
    );
  }
  else
  {
    return(
        <>
          <div className="top_content">
            <h1>SMS Settings</h1>
          </div>
          <div className="editor">
            <Mutation mutation={ADJUST_SMS_MESSAGE}>
              {(editSMS) => {
                return (
                    <form autoComplete="off" encType={'multipart/form-data'} onSubmit={(e) => {
                      e.preventDefault()

                      editSMS({
                        variables: {
                          SMS: smsMessage
                        }
                      }).then((result) => {
                        window.location.reload()
                      })

                    }}>

                      <div style={{marginBottom: "40px"}}>
                        <FormControl style={{width:"100%"}}>
                          <TextField id="outlined-basic" label="SMS Message" aria-describedby="my-helper-text" variant="outlined" value={smsMessage} onChange={(e) => {setSMSMessage(e.target.value)}} multiline rows={4} required/>
                          <FormHelperText id="my-helper-text">Note: (Name) is replaced with name of the client and (Time) is replaced with time of the appointment.</FormHelperText>

                        </FormControl>
                      </div>
                      <div className="button_div">
                        <Button className="contact_button" variant="contained" color="primary" type="submit">
                          Change
                        </Button>
                      </div>
                    </form>
                )
              }}
            </Mutation>
          </div>
        </>
    );
  }
}

function Body(props)
{
  let content = <Physios/>;
  const {getTokenSilently} = useAuth0();
  const [token, setToken] = useState();
  getTokenSilently().then(response => {setToken(response)})

  if (props.current_state === "physios")
    content = <Physios />;
  else if (props.current_state === "services")
    content = <Services />;
  else if (props.current_state === "sms")
    content = <SMS />;

  return (
    <div className="main">
      {content}
    </div>
  );
}

function Admin() {
  
  const [current_state, setCurrentState] = usePersistedState('current_state', 'physios')
  const { getTokenSilently, isAuthenticated, logout } = useAuth0();
  getTokenSilently().then(response => userToken = response)
  return (
    <div className="Admin">
      <div className="sidenav">
        <img src={logo} />
        <button className= {current_state === "physios" ? "current nav_button" : "nav_button"} onClick={()=>{setCurrentState("physios")}}>Clinicians</button>
        <button className={current_state === "services" ? "current nav_button" : "nav_button"} onClick={()=>{setCurrentState("services")}}>Services</button>
        <button className={current_state === "sms" ? "current nav_button" : "nav_button"} onClick={()=>{setCurrentState("sms")}}>SMS Settings</button>
        <div className="logout_button_div">
          {isAuthenticated && <button className="logout_button" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>}
        </div>
      </div>
      <Body current_state={current_state}/>
    </div>
  );
}

function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    () => JSON.parse(localStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
export default Admin;