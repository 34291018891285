import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
 
const GET_SERVICES = gql`

query getServices
    {
        getAllServices{
            id
            name 
        }
    }

`; 

function GetServices()
{
       
    //executing the query
    const { loading, data, error } = useQuery(GET_SERVICES)

  if (loading) return "LOADING";
  if(error){
      console.log(error)
  }
  return data.getAllServices;
  
}

export default GetServices;
